@font-face {
  font-family: sketch me;
  src: url(../public/fonts/SketchMeFreeVersion-qxyl.ttf);

  font-family: milkylemon;
  src: url(../public/fonts/milkylemon.ttf);
}

body {
  --bs-body-font-size: 1.5rem;
  --bs-nav-link-font-size: 1.7rem;
  --bs-body-color: #000;
  --bs-link-color-rgb: 35, 0, 223;
}

body {
  font-family: TenorSans !important;
  src: url(../public/fonts/TenorSans-Regular.ttf);
  background-color: #FF8872 !important;
  text-align: left !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
nav {
  font-family: milkylemon !important;
  font-size: 11.5px;
}

.container {
  max-width: 950px !important;
}

.navbar {
  background-image: linear-gradient(#ED4656 100%, #ED4656);
  padding-left: 0px !important;
  padding-right: 250px !important;
  height: 120px;
}

@media (max-width: 1200px) {
  .navbar {
     height: max-content;
  }
}

footer {
  background-image: linear-gradient(to top, #ED4656 95%, #ED4656);
  color: #000000;
}

.bi-telegram {
  color: #3390ec;
  font-size: 3em;
}

.bi-telegram:hover {
  color: #fff;
}

a:hover {
  color: #6c4981;
}

.nav-link {
  white-space: nowrap;
  color: #000 !important;
}

.nav-item {
  margin-right: 0.5em;
  margin-left: 0em;
  padding-inline: 0.5em;
  font-size: 10px;
}

.nav-link:hover {
  color: #fff !important;
}

@media (max-width: 900px) {
  .nav-link {
    font-size: 3em;
  }
}

.card {
  background-color: #F36976 !important;
}

.card-header:hover {
  cursor: pointer;
}

.mailLink {
  text-decoration: none;
  color: #000;
}

.mailLink:hover {
  color: #fff;
}

.headerIcons {
  margin-right: 0em;
  margin-left: 1em;
  font-size: 1.2em;
  color: #000;
}

.headerIcons:hover {
  color: #fff;
  font-size: 1.2em;
}

.footerIcons {
  margin-left: 9.5em !important;
  font-size: 2em;
  color: #000;
}

.footerIcons:hover {
  color: #fff;
}


.CollapsibleCard {
  font-size: 11px;
  font-family: TenorSans !important;
  src: url(../public/fonts/TenorSans-Regular.ttf);
  font-weight: bold;
}


.image-gallery {
  display: flex;
}

.image-container {
  margin-right: 10px; /* Abstand zwischen den Bildern */
}

.zoomable-image {
  max-width: 100%;
  width: 700px;
  height: auto;
  transition: transform 2.3s ease; /* Animation für den Zoom */
}

.zoomable-image:hover {
  transform: scale(1.2); /* Beim Überfahren das Bild zoomen */
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.white-text {
  color: white;
}

/* inside an <address> tag, add email and telephone number prefixes */
address a[href^='mailto']::before {
  content: '📧 ';
}

address a[href^='tel']::before {
  content: '📞 ';
}

address a[href^='https://']::before {
  content: '🏠 ';
}
